<template>
  <div>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">Manage Permission Sets</div>
    <div class="col-lg-7 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob">
      <!-- <button type="button" @click="addRolemodalOpen" class="header-btn-outer btn btn-primary">
        Add Permission Set
      </button> -->
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="roleList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :rows="10"
      responsiveLayout="scroll" :lazy="true" dataKey="at1" :loading="loading" :rowHover="true"
      :totalRecords="totalRecords" :paginator="true" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="Model" header="Permission Sets" headerStyle="width: 50%" bodyStyle="width: 50%"
        style="min-width: 250.95px">
        <template #body="{ data }">
          <div >{{
              data.at2 ? data.at2 : "N/A"
            }}
          </div>
        </template>
      </Column>
      <Column field="Status" header="Status" class="justify-content-center" headerStyle="width: 35%"
        bodyStyle="width: 35%" style="min-width: 200.8px">
        <template #body="{ data }">
          <div>
            <InputSwitch v-model="data.at5" :true-value="dynamicTrueActiveValue" :false-value="dynamicFalseInactiveValue"
            @change="confirmModalOpen(data)"/>
          </div>
        </template>
      </Column>
      <Column field="Action" header="Action" class="justify-content-center" headerStyle="width: 15%"
        bodyStyle="width: 15%" style="min-width: 159.48px">
        <template #body="{ data }">
          <button type="button" class="btn custom-view-detail-btn" @click="editRolemodalOpen(data)">
            View Details
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- Add new user role modal start here  -->
  <!-- <div class="modal-mask" v-if="addUserRoleDialog">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Add Permission Set
          </h5>
          <button type="button" class="btn-close" @click="addRolemodalClose()"></button>
        </div>
        <div class="modal-body modal-body-scroll-outer">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label for="formPermissionNameInput" class="form-label">Permission Set Name<span
                    class="text-danger">*</span></label>
                <input type="text" v-model="add.role" class="form-control text-capitalize" id="formSetNameInput"
                  placeholder="Enter Permission Set Name" autocomplete="off" maxlength="50"
                  v-on:keypress="isLetterWithSpace($event)" />
                <div class="custom-error" v-if="v$.add.role.$error">
                  {{ v$.add.role.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group mb-0">
                <label for="formModulesameInput" class="form-label">Select Modules<span
                    class="text-danger">*</span></label>
                <div class="flex flex-wrap gap-2 mb-2">
                  <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll()">
                    <i class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All
                  </button>
                  <button type="button" class="btn custom-view-detail-btn" @click="collapseAll()">
                    <i class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All
                  </button>
                </div>
                <Tree :value="nodes" selectionMode="checkbox" v-model:selectionKeys="add.selectedTreeValue"
                  :expandedKeys="expandedKeys" class="custom-users-role">
                </Tree>
                <div class="custom-error" v-if="v$.add.selectedTreeValue.$error">
                  {{ v$.add.selectedTreeValue.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn user-role-btn text-center" @click="addNewUserRole" :disabled="showaddloader">
              <span v-if="!showaddloader">Save</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showaddloader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Add new user role modal end here  -->
  <!-- Edit user role modal start here  -->
  <div class="modal-mask" v-if="editUserRoleDialog">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            View Permission Set
          </h5>
          <button type="button" class="btn-close" @click="editRolemodalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="modal-body-fixed-height">
            <div v-if="!showmodalloader">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label for="formsetNameInput" class="form-label">Permission Set Name<span
                        class="text-danger">*</span></label>
                    <input type="text" v-model="edit.role" disabled class="form-control text-capitalize" id="formsetNameInput"
                      placeholder="Enter Permission Set" autocomplete="off" maxlength="20"
                      v-on:keypress="isLetterWithSpace($event)" />
                    <div class="custom-error" v-if="v$.edit.role.$error">
                      {{ v$.edit.role.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label">Select Modules<span
                        class="text-danger">*</span></label>
                    <div class="flex flex-wrap gap-2 mb-2">
                      <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll">
                        <i class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All
                      </button>
                      <button type="button" class="btn custom-view-detail-btn" @click="collapseAll">
                        <i class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All
                      </button>
                    </div>
                    <Tree :value="nodes" disabled selectionMode="checkbox" v-model:selectionKeys="edit.selectedTreeValue"
                      :expandedKeys="expandedKeys" class="custom-users-role">
                    </Tree>
                    <div class="custom-error" v-if="v$.edit.selectedTreeValue.$error">
                      {{ v$.edit.selectedTreeValue.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row mt-4 text-end">
                <div class="col-lg-12 col-md-12 col-12">
                  <button type="button" class="btn user-role-btn text-center" @click="updateUserRole()"
                    :disabled="editbtnloader">
                    <span v-if="!editbtnloader">Update</span>
                    <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="editbtnloader">
                    </div>
                  </button>
                </div>
              </div> -->
            </div>
            <div v-if="showmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit user role modal start here  -->
  <!-- confirmation modal start here -->
  <div class="modal-mask" v-if="confirm_popup_status">
      <div
        class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal"
      >
        <div class="modal-content text-center">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="confirm-dialog-header">Confirmation</div>
              </div>
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i
                      class="pi pi-exclamation-triangle"
                      style="font-size: 2rem"
                    ></i>
                  </div>
                  <div class="flex-grow-1 ms-3 confirm-dialog-body">
                    Are you sure you want to proceed?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-end">
              <button
                type="button"
                :disabled="showconfirmloaderbtn"
                class="btn confirm-modal-no-btn"
                @click="confirmModalClose()"
              >
                No
              </button>
              <button
                type="button"
                class="btn confirm-modal-yes-btn"
                @click="changeStatus()"
                :disabled="showconfirmloaderbtn"
              >
                <span v-if="!showconfirmloaderbtn">Yes</span>

                <div
                  class="spinner-border text-light custom-spinner-loader-btn"
                  role="status"
                  v-if="showconfirmloaderbtn"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- confirmation modal end here -->
  </div>
</template>

<script>
import ApiService from "../../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useValidate(),
      loading: false,
      roleList: null,
      nodes: [],
      expandedKeys: {},
      add: {
        role: "",
        selectedTreeValue: [],
      },
      edit: {
        role: "",
        selectedTreeValue: [],
      },
      addUserRoleDialog: false,
      editUserRoleDialog: false,
      editbtnloader: false,
      showaddloader: false,
      errormsg: "",
      roleid: "",
      showmodalloader: false,
      totalRecords: 0,
      switchuncheck: false,
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
      confirm_popup_status:false,
      user_id:"",
      user_status:""
    };
  },
  validations() {
    return {
      add: {
        role: {
          required: helpers.withMessage(
            "Please enter permission set name",
            required
          ),
        },
        selectedTreeValue: {
          required: helpers.withMessage("Please select modules", required),
        },
      },
      edit: {
        role: {
          required: helpers.withMessage(
            "Please enter permission set name",
            required
          ),
        },
        selectedTreeValue: {
          required: helpers.withMessage("Please select modules", required),
        },
      },
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.getRoleList();
    this.getModules();
  },
  methods: {
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z0-9\s]*$/.test(charspace)) return true;
      else e.preventDefault();
    },

    getRoleList() {
      this.loading = true;
      this.ApiService.getRoleList({ at4: 2 }).then((data) => {
        if (data.status == 200) {
          this.roleList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.roleList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getRoleList({ page_no: this.page_no });
    },
    getModules(ev) {
      this.ApiService.getModules(ev).then((data) => {
        if (data.status == 200) {
          this.nodes = data.data;
        }
      });
    },
    expandAll() {
      for (let node in this.nodes) {
        this.expandNode(this.nodes[node]);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },

    collapseAll() {
      this.expandedKeys = {};
    },

    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;

        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },

    addRolemodalOpen() {
      this.addUserRoleDialog = true;
      this.add.role = "";
      this.add.selectedTreeValue = [];
    },
    addRolemodalClose() {
      this.addUserRoleDialog = false;
      this.add.role = "";
      this.add.selectedTreeValue = [];
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
      this.expandedKeys = {};
    },
    editRolemodalOpen(e) {
      this.editUserRoleDialog = true;
      this.showmodalloader = true;
      this.edit.role = "";
      this.edit.selectedTreeValue = [];
      this.roleid = e.at1;
      this.edit.role = e.at2;
      if (e.at3 != null) {
        let arrConverted = e.at3.split(",");
        this.edit.selectedTreeValue = arrConverted;
        for (let index = 0; index < arrConverted.length; index++) {
          let idxx = arrConverted[index];
          this.edit.selectedTreeValue = {
            ...this.edit.selectedTreeValue,
            [idxx]: { checked: true, partialChecked: false },
          };
        }
      }
      this.showmodalloader = false;
    },
    editRolemodalClose() {
      this.editUserRoleDialog = false;
      this.edit.role = "";
      this.edit.selectedTreeValue = [];
      this.expandedKeys = {};
    },
    confirmModalOpen(data){
      this.confirm_popup_status = true;
      this.user_id = data.at1;
      this.user_status = data.at5;
    },
    changeStatus() {
      let fields = {};
      fields["at1"] =  this.user_id ;
      if (this.user_status == 0 || this.user_status == false) {
        fields["at5"] = 0;
      }
      if (this.user_status == 1 || this.user_status == true) {
        fields["at5"] = 1;
      }
      this.ApiService.updaterolestatus(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getRoleList();
        } else {
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
        this.confirm_popup_status = false;
      });
    },

    addNewUserRole() {
      this.v$.add.$validate();
      let fields = {};
      fields["at2"] = this.add.role;
      if (this.add.selectedTreeValue) {
        fields["modules"] = this.add.selectedTreeValue;
      }
      if (!this.v$.add.$error) {
        this.showaddloader = true;
        this.ApiService.addnewuserrole(fields).then((items) => {
          if (items.status === 200) {
            this.showaddloader = false;
            this.addUserRoleDialog = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.getRoleList();
            this.add.role = "";
            this.add.selectedTreeValue = [];
          } else {
            this.addUserRoleDialog = true;
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showaddloader = false;
          }
        });
      }
    },
    updateUserRole() {
      this.v$.edit.$validate();
      let fields = {};
      fields["at1"] = this.roleid;
      fields["at2"] = this.edit.role;
      if (this.edit.selectedTreeValue) {
        fields["modules"] = this.edit.selectedTreeValue;
      }
      if (!this.v$.edit.$error) {
        this.editbtnloader = true;
        this.ApiService.updateuserrole(fields).then((items) => {
          if (items.status === 200) {
            this.editbtnloader = false;
            this.editUserRoleDialog = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.getRoleList();
            this.edit.role = "";
            this.edit.selectedTreeValue = [];
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.editbtnloader = false;
            this.editUserRoleDialog = true;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-users-role {
  max-height: 250px;
  overflow-y: auto;
}

.custom-users-role::-webkit-scrollbar {
  display: block !important;
  width: 0.3em !important;
  height: 0.3em !important;
}

.custom-users-role::-webkit-scrollbar-thumb {
  background-color: rgba(31, 30, 30, 0.19) !important;
  border-radius: 3px !important;
}

.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.user-role-btn:focus {
  box-shadow: none;
}
</style>

